import React, { Component } from "react";

import Quran from "../../Quran/Quran";

class TextDisplay extends Component {
  render() {
    return <Quran />;
  }
}

export default TextDisplay;
